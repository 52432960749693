
import { defineComponent } from "vue";
import MessageAnimated from "@/components/message-animated.vue";

export default defineComponent({
    components: { MessageAnimated},
    name: "Megan",
    methods: {
        async checkMessageStatus(i: number) {
            if (this.paragraphs[this.activeIndex].strings.length == i) {
                this.activeIndex++;
                if (this.activeParagraphs.length >= this.paragraphs.length) {
                    await new Promise(r => setTimeout(r, 7000));
                    this.doneParagraphs = true;
                }
            }
        },
        stopScrolling() {
            clearInterval(this.scrollTask);
        },
        scrollToBottom() {
            const container = this.$refs.msgContainer as HTMLDivElement;
            container.scrollTop = container.scrollHeight;
        }
    },
    mounted() {
        this.scrollTask = setInterval(() => {
            this.scrollToBottom();
        }, 250);
    },
    data() {
        return {
            activeIndex: 0,
            scrollTask: 0,
            doneParagraphs: false,
            paragraphs: [
                {
                    strings: ["hi."],
                    typeSpeed: 60,
                    delayStart: 1000,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "at first i was planning to mail a letter,",
                        "&nbsp;but then we had to quarantine, so",
                        "&nbsp;mail letter later",
                        ", yes?"
                    ],
                    typeSpeed: 30,
                    delayStart: 1000,
                    delayBetweenStrings: 500
                },
                {
                    strings: [
                        "i actually don't know how to write personal letters.",
                        "&nbsp;the only ones i remember writing were formal",
                        ", and any journals i tried to start ended up feeling kind of forced."
                    ],
                    typeSpeed: 30,
                    delayStart: 1500,
                    delayBetweenStrings: 500
                },
                {
                    strings: [
                        "so usually this is where i fall back to my comfort zone and just show a picture.",
                        "&nbsp;but i'll try to use words this time."
                    ],
                    typeSpeed: 30,
                    delayStart: 1000,
                    delayBetweenStrings: 900
                },
                {
                    strings: [
                        "people don't typically make me want to reach out very often.",
                        "&nbsp;it comes pretty naturally with you now though",
                        ", after i <span style='color:#888;'>(mostly)</span> got over the fear of being too creepy."
                    ],
                    typeSpeed: 30,
                    delayStart: 1800,
                    delayBetweenStrings: 900
                },
                {
                    strings: [
                        "you're the coolest person i've known.",
                        "&nbsp;here's a non-exhaustive list of supporting evidence:"
                    ],
                    typeSpeed: 30,
                    delayStart: 1400,
                    delayBetweenStrings: 1000
                },
                {
                    strings: [
                        "- you like dogs.",
                    ],
                    typeSpeed: 30,
                    delayStart: 1000,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you like cats.",
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you've had dogs",
                        "&nbsp;<i>and</i> cats!"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 100
                },
                {
                    strings: [
                        "- your favourite animal wears a tuxedo.",
                        "&nbsp;tuxedos are <span style='background-color:#daffff;'>hella cool</span>."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 100
                },
                {
                    strings: [
                        "- your name game is <b>on point.</b>",
                        "&nbsp;brutus mchappybuns merriweather whipplethorpe the freaking iii!"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 200
                },
                {
                    strings: [
                        "- <b><i>winnie the freaking pooh!!</i></b>"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you're allergic to avocados and you <u>love them</u>."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you have geeky music toys."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- your cat peed on one of them and you still love her."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- your personal interpretation of an ensemble led to a rewrite of your teacher's."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- your idle clicking on the server never fails to shake things up."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you're so unproblematic that when i went to do the mod performance review roundup,",
                        `&nbsp;i fumbled and went for some weak variation of <i>"you're such a perfectionist"</i> just to make the other mods not look bad.`
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 400
                },
                {
                    strings: [
                        "(it wasn't meant to be an actual judgement on your character,",
                        "&nbsp;and i'm sorry i conveyed otherwise.)"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 400
                },
                
                {
                    strings: [
                        "- you enlisted the help of a <span style='border-bottom: 0.2em solid orange;'>chicken</span> to fight white supremacy",
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- and flipped the bird at me when i commented on the lack of <i>spice</i> in thumbs up reactions.",
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "(it was an honour to receive.)",
                    ],
                    typeSpeed: 30,
                    delayStart: 150,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- using the sun emoji <span style='color: orange;'>&#x2600;</span> as a substitute for meepeem!beaker was brilliant."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- and your taste in smilies (smileys?) is <span style='border-bottom: 0.2em solid orange;'>top-tier</span>. :]"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- your ability to form connections between seemingly unrelated topics is an absolute joy to see."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- you comparing my fav frozen song to <u>bach</u> was high praise"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- and you <span style='border-bottom: 0.2em solid orange;'>playing</span> my barely-started arrangement was higher so."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- your stories are a continual source of inspiration for my drawings"
                    ],
                    typeSpeed: 30,
                    delayStart: 500,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- and your drawings carry a carefree energy i aspire toward.",
                        "&nbsp;thanks for reminding me to have fun."
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 500
                },
                {
                    strings: [
                        `- sometimes when i'm struggling to talk about something, you say <span style='background-color:#daffff'>"i&nbsp;know."</span>`,
                        "&nbsp;it's a small gesture, but it means a lot."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 400
                },
                {
                    strings: [
                        "- sometimes when i'm in the middle of looping through some rude life puzzle, you suddenly pop up asking how i like my <span style='border-bottom: 0.2em solid orange;'>toast toasted</span>.",
                    ],
                    typeSpeed: 30,
                    delayStart: 500,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "<i>just enough that whatever needs to melt melts, thank you.</i>"
                    ],
                    typeSpeed: 30,
                    delayStart: 300,
                    delayBetweenStrings: 0
                },
                {
                    strings: [
                        "- going out to take pictures of squirrels to show you was one of the highlights of my year",
                        ", along with seeing <u>you</u> return the favour in the middle of a lesson."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 450
                },
                {
                    strings: [
                        "- you used the phrase <i>geeky proclivities</i> to describe your (wonderful) interest in Star Wars."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 450
                },
                {
                    strings: [
                        "- the way you tie in jokes to callbacks from previous convos highlights both your <i>impeccable</i> sense of humour and your consistent effort to listen."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 450
                },
                {
                    strings: [
                        "- sometimes i forget what i meant to say and brush it off as unimportant",
                        `, and you'd say <span style='background-color:#daffff'>"everything you say is important."</span>`,
                        "&nbsp;i really appreciate that."
                    ],
                    typeSpeed: 30,
                    delayStart: 400,
                    delayBetweenStrings: 500
                },
                {
                    strings: [
                        "i know i'll be adding more to this list as time goes by",
                        ", whether you see it here or not.",
                        "&nbsp;i'm not sure how the rest of this year will go",
                        ", but i know it'll be better with you around.",
                    ],
                    typeSpeed: 30,
                    delayStart: 900,
                    delayBetweenStrings: 600
                },
                {
                    strings: [
                        "i'm grateful i got the chance to know you",
                        ", and i'm so glad you're here.",
                        "&nbsp;happy birthday, megan."
                    ],
                    typeSpeed: 30,
                    delayStart: 900,
                    delayBetweenStrings: 550
                }
            ]
        }
    },
    computed: {
        activeParagraphs(): Array<{strings: string[], typeSpeed: number, delayStart: number, delayBetweenStrings: number}> {
            return this.paragraphs.slice(0, this.activeIndex + 1);
        }
    }
});
