import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "messages",
  ref: "msgContainer"
}
const _hoisted_2 = { class: "signature" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_animated = _resolveComponent("message-animated")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeParagraphs, (p, i) => {
      return (_openBlock(), _createElementBlock("p", { key: i }, [
        _createVNode(_component_message_animated, {
          strings: p.strings,
          typeSpeed: p.typeSpeed,
          delayStart: p.delayStart,
          delayBetweenStrings: p.delayBetweenStrings,
          idPrefix: `p${i}`,
          onStringtyped: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkMessageStatus($event)))
        }, null, 8, ["strings", "typeSpeed", "delayStart", "delayBetweenStrings", "idPrefix"])
      ]))
    }), 128)),
    _createElementVNode("p", _hoisted_2, [
      (_ctx.doneParagraphs)
        ? (_openBlock(), _createBlock(_component_message_animated, {
            key: 0,
            strings: ['- ashe'],
            onStringtyped: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stopScrolling()))
          }))
        : _createCommentVNode("", true)
    ])
  ], 512))
}